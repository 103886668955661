.downloadText {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    font-family: Montserrat;
    padding-top: 100px;
    padding-bottom: 40px;
    color: rgba(20, 19, 18, 0.87);
}
.downloadContainer{
    background-color:#F5F5F5;
    padding-bottom: 100px;
}
.downloadHuawei {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Montserrat';
    font-size: 18px;
}
.downloadHref {
    border-bottom: 2px solid transparent;
    margin-left: 10px;
    text-decoration: none;
    color: #0d6efd;
    text-align: center;
}
.downloadHref:hover {
    color: #0d6efd;
    border-bottom: 2px solid #0d6efd;
}
.downloadImgHuawei {
    padding-top: 100px;
    padding-bottom: 30px;
    width: 150px;
}

.downloadImgApk {
    padding-top: 100px;
    padding-bottom: 30px;
    width: 200px;
    border-radius: 10px;
}

.downloadColHuawei {
    background-color: white;
    margin-right: 20px;
    padding: 0;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    z-index: 1;
}



@media screen and (max-width: 1400px) {
    .downloadImgHuawei {
        padding-top: 85px;
        padding-bottom: 30px;
        width: 120px;
    }
}
@media screen and (max-width: 1200px) {
    .downloadImgHuawei {
        padding-top: 60px;
        padding-bottom: 30px;
        width: 100px;
    }
    .downloadBtnHuawei {
        margin-right: 20px;
    }
}

@media screen and (max-width: 1049px) {
    .downloadBtnHuawei {
        margin-right: 0;
    }
}

@media screen and (max-width: 992px) {
    .downloadImgHuawei {
        padding-top: 70px;
        padding-bottom: 30px;
        width: 150px;
    }
}

@media screen and (max-width: 767px) {
    .downloadColHuawei {
        margin-right: 0;
    }
}

@media screen and (max-width: 778px) {
    .downloadText {
        padding-top: 50px;
        padding-bottom: 24px;
        font-size: 24px;
    }
    .downloadContainer {
        padding-bottom: 50px;
    }
}