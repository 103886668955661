.productImg {
    width: 350px;
    height: auto;
}
.productContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}
.productContent {
    font-style: normal;
    font-weight: 800;
    font-size: 37px;
    color: rgba(20, 19, 18, 0.87);
    font-family: 'Montserrat', sans-serif;
    
}
.productCaption {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(20, 19, 18, 0.6);
}
.productButton {
    text-decoration: none;
    scroll-behavior: smooth;
}
.productButton:hover{
    text-decoration: none;
}
#newLine {
    display: none;
}
#newLineLast {
    display: none;
}
.productButton button{
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 14px 36px;
    font-weight: 500;
    font-size: 20px;
    color: #F5F5F5;
    background: linear-gradient(rgba(34, 103, 180, 1)
    ,rgba(89, 139, 194, 1));
    box-shadow: 0px 8px 24px rgba(2, 154, 182, 0.32);
    border-radius: 100px;
    outline: none;
    border:none;
    transition: 0.1s ease-in;
}
.productButton  button:hover {
    transition: 0.1s ease-in;
    box-shadow: 0px 8px 16px rgba(2, 154, 182, 0.16);
    outline: none;
}
#productBtn {
    outline: none;
}
.productButton:active {
    transform: translateY(2px);
}
@media screen and (max-width: 928px) {
    .productContent {
        font-size:33px;
    }
    .productCaption {
        font-size: 14px;
        padding: 0px 15px;
        text-align: center;
    }
}
@media screen and (max-width: 768px) {
    .productContent {
        font-size:33px;
    }
    #newLine {
        display: block;
    }
    #newLineLast {
        display: block;
    }
    .productContent {
        line-height: 32px;
    }
    .productCaption {
        margin-top: 12px;
    }
}
@media screen and (max-width: 500px) {
    .productContent {
        font-size:28px;
    }
    .productButton button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin: 8px 0;
    }
    .productImg {
        padding: 0 15px;
    }
}
@media screen and (max-width: 340px) {
    #newLineLast {
        display: none;
    }
}