#btnDown {
    outline: none;
}

.downloadBtn {
    text-decoration: none;
    padding:0 60px 44px 60px;
    width: 100%;
    display: block;
    outline: none;
}
.downloadBtn:hover {
    text-decoration: none;
}
.downloadBtn button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 14px 56px;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: 'Montserrat';
    background-color: white;
    font-size: 20px;    
    line-height: 32px;
    color: rgba(20, 19, 18, 0.87);
    font-weight: 500;
    outline: none;
}
.downloadBtn button:hover {
    background: #F6F6F6;
    border: 1px solid #E6E6E6;
}

.downloadImg {
    padding-top: 44px;
    padding-left: 44px;
    padding-right: 44px;
}
.downloadCol {
    background-color: white;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 44px 60px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    z-index: -1;
}
@media screen and (max-width: 1050px) {
    .downloadBtn button {
        font-size: 16px;
        padding: 14px 6px;
        width: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .downloadBtn button {
        font-size: 14px;
        padding: 14px 6px;
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .downloadCol {
        margin-bottom: 20px;
        padding: 0 16px;
    }
    .downloadBtn button {
        font-size: 20px;
    }
}
@media screen and (max-width: 767px) {
    .downloadCol {
        margin-right: 0px;
    }
}
@media screen and (max-width: 500px) {         
    .downloadBtn {
        padding: 0px 36px 24px 36px;
    }
    .downloadBtn button {
        font-size: 16px;
    }
}