.notfound-container {
    background: rgb(245,245,245);
    margin-top: 20px;
    padding: 100px 0;
}
.notfound-title {
    background-color: #f5f5f5;
    font-family: "Montserrat",sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: center
}
.notfound-text {
    margin-top: 50px;
    margin-bottom: 70px;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    position: relative;
    padding: .75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-family: "Montserrat",sans-serif;
    text-align: center;
}