.questionsContainer {
    border-top: 2px solid #E6E6E6;
    background-color: rgb(245,245,245);
    padding-bottom: 100px;
}
.questionsCaption {
    padding-bottom: 48px;
    padding-top: 100px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    color: rgba(36, 36, 36, 0.87);
    text-align: center;
}
#questionsBtn {
    outline: none;
}
.questionsBtn {
    margin-top: 48px;
    background: linear-gradient(rgba(34, 103, 180, 1),rgba(89, 139, 194, 1));
    box-shadow: 0px 8px 16px rgba(1, 153, 181, 0.16);
    border-radius: 24px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 42px;
    font-family: Montserrat;
    border:none;
    outline: none;
}
.questionsBtn:hover {
    box-shadow: 0px 8px 16px rgba(1, 153, 181, 0.36);
}

@media screen and (max-width: 600px) {
    #questionsContainer {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 70px;
    }
    .questionsCaption {
        padding-top: 60px;
        padding-bottom: 16px;
    }
    .questionsBtn {
        margin-top: 20px;
    }
}