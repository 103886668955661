.footerContainer {
    padding-top: 60px;
    padding-bottom: 60px;
}
.footerCaption {
    font-weight: 500;
    font-size: 20px;
    color: rgba(20, 19, 18, 0.87);
    line-height: 28px;
    font-family: 'Montserrat', sans-serif;
}
.footerText {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ACABAB;
}
.footerAdress {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ACABAB;
}
.footerAdress:hover {
    color: #ACABAB;
}
.footerTell {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #ACABAB;
    padding-top: 20px;
    height: 28px;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
} 
.social-group {
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;
}
.social-group a {
    margin: 0 3px;
}
.footerTell:hover{
    color: #ACABAB;
}
.footerLast {
    font-family: 'Montserrat', sans-serif;
    background: #F5F5F5;
}
.footerLastCopy {
    font-family: 'Montserrat', sans-serif;
    padding: 24px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ACABAB;
    margin-bottom: 0px;
}
.footerLastText {
    margin-bottom: 0px;
    font-family: 'Montserrat', sans-serif;
    padding: 24px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ACABAB;
}
.footerCaptionLast {
    font-weight: 500;
    font-size: 20px;
    color: rgba(20, 19, 18, 0.87);
    line-height: 28px;
    font-family: 'Montserrat', sans-serif;
    
}

@media screen and (max-width: 767px) {
    .footerContainer {
        padding-top: 30px;
    }
    .footerCaptionLast {
        margin-right: 0;
        margin-top: 20px;
        text-align: left;
    }
    .footerTell {
        padding-top: 10px;
    }
    .footerLastText {
        padding-top: 0;
        margin-top: 10px;
    }
    .footerLastCopy {
        padding-bottom: 0;
    }
    .footerLastCopyCol {
        padding-left: 0px;
    }
}