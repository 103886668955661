body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}
* {
  outline: none;
}
#footerCont {
  margin-top: auto;
}
.moveTop-hide {
  display: none;
  position: fixed;
  bottom: 30px;
  left: 20px;
}
.moveTop {
  width: 65px;
  height: 65px;
  background-color: rgba(34, 103, 180, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
  left: 20px;
  cursor: pointer;
  animation: topScroll .3s linear;
  transform: translateY(10px);
  z-index: 100;
}
.moveTop img {
  width: 12px;
  transform: rotate(-90deg);
}
@keyframes topScroll {
  0% {
      opacity: 0;
      transform: translateY(0px)
  }
  100% {
      opacity: 1;
      transform: translateY(10px);
  }

}