.accordionClick {
    user-select: none;
    cursor: pointer;
}
.accordionText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 15px 20px 32px;
}
.accordionParent {
    background-color: white;
    margin-bottom: 8px;
    border-radius: 8px;
    overflow: hidden;
    width: 780px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
}
.accordionImg {
    width: 10px;
    transform: rotate(90deg);
}
.accordionImg_active {
    width: 10px;
    transform: rotate(-90deg);
}
.imgBox {
    margin-right: 32px;
}
#accordionContainer {
    display: flex;
    justify-content: center;
}
.accordionLastText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(36, 36, 36, 0.6);
    overflow: hidden;
    height: 0px;
}
.accordionParent_active {
    background-color: white;
    margin-bottom: 8px;
    border-radius: 8px;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.16);
    width: 780px;
}
.accordionLastText_active{
    padding: 5px 32px 24px 32px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(36, 36, 36, 0.6);
    height: auto;
}
@media screen and (max-width: 650px) {
    .accordionText {
        padding: 20px 15px 20px 15px;
    }
    .imgBox {
        margin-right: 15px;
    } 
    .accordionLastText_active {
        padding: 5px 15px 24px 15px;
    }
    #accordionContainer {
        padding-left: 0px;
        padding-right: 0px;
    }
}