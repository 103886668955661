.legalContainer {
    background: #f5f5f5;
    padding-bottom: 80px;
}
.legalTitle {
    font-size: 24px;
    font-family: 'Montserrat';
    text-align: center;
    font-weight: 800;
    color: rgba(36, 36, 36, 0.87);
    padding-bottom: 10px;
}
.legalContainer a {
    font-size: 16px;
    font-family: 'Montserrat';
    text-align: center;
    font-weight: 800;
    display: block;
    text-decoration: none;
    padding-bottom: 16px;
}