.colCaption {
    padding-top: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: rgba(20, 19, 18, 0.87);
}
.colText {
    text-align: center;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: rgba(114,113,113);
    padding-bottom: 40px;
    width: 216px;
    color: rgba(20, 19, 18, 0.87);
}