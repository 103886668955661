.advantageContainer {
    background-color: rgba(245,245,245,1);
    border-bottom: 2px solid #E6E6E6;
}
.advantageCaption {
    padding-top: 100px;
    font-weight: 800;
    font-size: 28px;
    font-family: Montserrat;
    color: rgba(20, 19, 18, 0.87);
    line-height: 32px;
}
.advantageRow {
    padding-top: 50px;
    padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
    .advantageCaption {
        padding-top: 50px;
        font-size: 24px;
    }
    .advantageRow {
        padding-bottom: 50px;
    }
}